import { useCallback, useEffect, useState } from 'react'
import { HttpClientResponse } from '@gameforge/http-client'
import { apiClient, useBlackbox, useLoca } from '../../../core'
import { useUser } from '../../../providers'
import { useNavigate } from '../../../router'
import { useStateCredentials, Credentials } from './useStateCredentials'

export const useSignIn = () => {
  const navigate = useNavigate()
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState<boolean>(false)
  const { locale } = useLoca()
  const { refreshUser } = useUser()
  const credentials = useStateCredentials()
  const getBlackbox = useBlackbox()

  const signIn = useCallback(
    async (args: Credentials) => {
      if (busy) return
      setError(false)
      setBusy(true)

      const errorHandler = async (err: unknown) => {
        if (err instanceof HttpClientResponse) {
          const body = await err.json<{ errorTypes: string[] }>()
          const errorTypes = Array.isArray(body.errorTypes)
            ? body.errorTypes
            : []

          if (err.status === 403 && errorTypes.includes('USER_BANNED')) {
            return navigate('../banned', { state: { signInError: true } })
          }

          if (err.status === 409 && errorTypes.includes('OTP_REQUIRED')) {
            return navigate('2fa', {
              state: {
                ...args,
                error: errorTypes.includes('OTP_INVALID')
              }
            })
          }
        }

        setError(true)
      }

      return apiClient
        .postJson(`/sessions`, {
          ...args,
          provider: 'credentials',
          blackbox: await getBlackbox(),
          locale
        })
        .then(() => refreshUser())
        .catch(errorHandler)
        .finally(() => {
          setBusy(false)
        })
    },
    [locale, refreshUser, navigate, setError]
  )

  useEffect(() => {
    if (credentials) void signIn(credentials)
  }, [credentials, signIn])

  return { busy, credentials, error, signIn }
}
