import { addBreadcrumb } from '@sentry/react'
import { HttpClientResponse, Plugin } from '@gameforge/http-client'

export const reportBreadcrump: Plugin = () => ({
  report: async (req, resPromise) => {
    const start = Date.now()
    const res = await resPromise.catch((err: Error) => err)
    const duration = Date.now() - start

    if (res instanceof HttpClientResponse) {
      addBreadcrumb({
        category: 'api',
        message: `Made ${req.method} request to ${req.url}`,
        data: {
          requestId: res.headers.get('x-request-id'),
          duration
        },
        level: 'info'
      })
    } else {
      addBreadcrumb({
        category: 'api',
        message: `Failed ${req.method} request to ${req.url}`,
        data: { duration },
        level: 'error'
      })
    }
  }
})
